
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './style.css';


const AdminSupport = () => {
  const [result, setresult] = useState([]);
  useEffect(() => {
    getAllUsers();
  }, []); 

  async function  getAllUsers  ()  {
    try {
      const response = await axios.get('https://rxd7078.uta.cloud/php/fetch_contact_us.php'); // Update the URL with your backend endpoint
      const {result}=response.data;
      setresult(result); // Update state with fetched data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
//   async function deleteEntry(Objective_id) {
//     try {
//       await axios.post('https://rxd7078.uta.cloud/php/admin_delete_obj.php', {
//         Objective_id    :  Objective_id  // Send the student_id in the request body
//       });
//       alert("Course deleted successfully.");
//       getAllUsers();
//     } catch (error) {
//       console.error("Error deleting student:", error);
//     }
//   }


  const handleLogout = () => {

  }

  return (
    <div id="root">
      <div className="App">
        <div className="header">
          <div className="header-left">
            <div className="header-title">
              <Link to="/homepage"> {/* Use Link component for navigation */}
                <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
              </Link>
              <span className="heading">GradEdge</span>
            </div>
          </div>
          <div className="header-right">
            <Link to="/admin" className="header-title">HOME</Link>
            <Link to="/aboutus" className="header-title">ABOUT</Link>
            {/* Use the Link component for logout */}
            <span className="header-title" onClick={handleLogout} style={{ cursor: 'pointer' }}>
              LOGOUT
            </span>
          </div>
        </div>
        <div className="main-content-container">
          <div className="container">
            <br />
            <h2>Manage Program</h2>
            <table className="policy-table">
              <thead>
                <tr>
                <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Message</th>
                </tr>
                </thead>
              <tbody>
                
              {Array.isArray(result) && result.length > 0 ?(result.map((user, index) => (
                  
                  <tr key={index}>
                    <td>{user.contact_id}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.message}</td>
                
                  </tr>
                ))):""
            }
              </tbody></table>
          </div>
          <div className="chat-feedback">
            <Link to="/feedback" className="feedback-link">Feedback</Link>
            <div className="chat-icon">
              <a className="chat-hover">
                <Link to="/chat">
                  <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                </Link>
              </a>
            </div>
          </div>
          <div className="footer">
            <div className="footer-content">
              <div className="footer-left">
                <div className="about-text-footer">
                  <br />
                  College Of Computer Science
                  <br />
                  634 Nedderman Hall
                  <br />
                  Arlington, TX 76019
                </div>
              </div>
              <div className="footer-right">
                <div className="footer-links">
                  <Link to="/homepage" className="footer-links-title">HOME</Link>
                  <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
                  <Link to="/services" className="footer-links-title">SERVICES</Link>
                  <Link to="/contactUs" className="footer-links-title">CONTACT US</Link>
                </div>
              </div>
            </div>
            <br />
            <div className="footer-social-icons">
              <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
              <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
              <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
              <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
            </div>
            <br />
            @GradEdge Copyright 2023
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminSupport;
