

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'chart.js/auto';
import { Pie } from 'react-chartjs-2';

const AdminSupport = () => {
  const [course, setcourse] = useState([]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getRandomRGBColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r},${g},${b})`;
  };

  async function getAllUsers() {
    try {
      const response = await axios.get('https://rxd7078.uta.cloud/php/report.php');
      console.log(response.data.courses);
      console.log(course)
      setcourse(response.data.courses);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const data = {
    labels: course.map((item) => item.program),
    datasets: [
      {
        data: course.map((item) => item.Count),
        backgroundColor: course.map(() => getRandomRGBColor())
      },
    ],
  };

  const options = {
    title: {
      display: true,
      text: 'Programs',
    },
  };

//   return (
//     <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
//       <Pie data={data} options={options} style={{ width: '50%', height: '50vh' }}/>
//     </div>
//   );
const chartContainerStyle = {
    border: '5px solid #000',
  padding: '50px 70px', // No padding
  display: 'inline-block',
  marginTop:'50px',
 justifyContent: 'center', alignItems: 'center', height: '80vh'
  
  };

  const contentContainerStyle = {
    textAlign: 'center',
    marginTop: '20px', 
    marginBottom:'50px'
  };

  return (

      <div style={contentContainerStyle}>
    <div>
      <h1>Types of Different Programs and their count</h1>
    </div>
    <div style={chartContainerStyle}>
      <Pie data={data} options={options} style={{ width: '50%', height: '100vh' }} />
    </div>
  </div>
  );
};

export default AdminSupport;


    