// import React from 'react';
// import './style.css'; // Import your CSS file
// // import { Link } from "react-router-dom";
// import { Link, useHistory } from "react-router-dom";

// function Analytics() {
//   const history = useHistory();
  
//     const handleLogout = () => {
//       const confirmLogout = window.confirm("ARE YOU SURE YOU WANT TO LOGOUT?");
//       if (confirmLogout) {
//         history.push('/login'); // Replace with your login page route
//       }
//       // If the user chooses not to log out, the function will simply exit without any additional actions
//     };
//     return (
//         <html lang="en">
//             <head>
//                 <link rel="stylesheet" href="style.css" />
//                 <title>Analyticcs</title>
//             </head>
//             <body className="vsc-initialized">
//                 <div id="root">
//                     <div className="App">
//                         <div className="header">
//                             <div className="header-left">
//                                 <div className="header-title">
//                                     <a href="./homepage.html">
//                                         <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
//                                     </a>
//                                     <span className="heading">GradEdge</span>
//                                 </div>
//                             </div>
//                             <div className="header-right">
//                             <Link to="/homepage" className="header-title">HOME</Link>
//                                <Link to="/aboutus" className="header-title">ABOUT</Link>
//                                <span className="header-title" onClick={handleLogout} style={{ cursor: 'pointer' }}>LOGOUT</span>
//                             </div>
//                         </div>
//                         <div className="main-content-container">
//                         <div className="report-container">
//           <h1>Analytics</h1>
//           <br />
//           <p>Select user type:</p>
//           <form>
//             <input type="text" placeholder="Role" name="role" list="roles" required />
//             <datalist id="roles">
//               <option value="Student" />
//               <option value="Instructor" />
//             </datalist>
//             <br />
//             <div className="qa-img">
//               <img src="./chart.svg" alt="image1" className="qa-img" />
//             </div>
//           </form>
//         </div>
//                             <div className="chat-feedback">
//                                 <a href="./feedback.html" className="feedback-link">Feedback</a>
//                                 <div className="chat-icon">
//                                     <a href="./chat.html" className="chat-hover">
//                                        <Link to="/chat">
//                 <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link>
//                                     </a>
//                                 </div>
//                             </div>
//                              <div className="chat-feedback">
//           <Link to="/feedback" className="feedback-link">Feedback</Link>
//           {/* <a  className="feedback-link">Feedback <Link to="/feedback"></Link></a> */}
//             {/* <a href="./feedback.html" className="feedback-link">Feedback</a> */}
//             <div className="chat-icon">
//               <a  className="chat-hover"><Link to="/chat">
//                <Link to="/chat">
//                 <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link></Link>
//               </a>
//             </div>
//           </div>
//                             <div className="footer">
//               <div className="footer-content">
//                     <div className="footer-left">
//                       <div className="about-text-footer">
//                         <br />
//                         College Of Computer Science
//                         <br />
//                         634 Nedderman Hall
//                         <br />
//                         Arlington, TX 76019
//                       </div>
//                     </div>
//                     <div className="footer-right">
//                       <div className="footer-links">
//                         <Link to="/homepage" className="footer-links-title">
//   HOME
// </Link>
//                        <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
               
//                        <Link to="/services" className="footer-links-title">
//                           SERVICES</Link>
                   
//                        <Link to="/contactUs" className="footer-links-title">
//                           CONTACT US</Link>
//                       </div>
//                     </div>
//                   </div>
//                   <div>
//                     <br />
//                     <div className="footer-social-icons">
//                       <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
//                       <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
//                       <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
//                       <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
//                     </div>
//                     <br />
//                     @GradEdge Copyright 2023
//                   </div>              </div>
//                         </div>
//                     </div>
//                 </div>
//             </body>
//         </html>
//     );
// }

// export default Analytics;

import React from 'react';
import './style.css'; // Import your CSS file here
import { Link } from 'react-router-dom';

function Analytics() {
  return (
    <html lang="en">
      <head>
        <link rel="stylesheet" href="style.css" />
        <title>Analytics</title>
      </head>
      <body className="vsc-initialized">
        <div id="root">
          <div className="App">
            <div className="header">
              <div className="header-left">
                <div className="header-title">
                  <a href="./homepage.html">
                    <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                  </a>
                  <span className="heading">GradEdge</span>
                </div>
              </div>
              <div className="header-right">
                <Link to="/homepage" className="header-title">HOME</Link>
                <Link to="/aboutus" className="header-title">ABOUT</Link>
                <span className="header-title" style={{ cursor: 'pointer' }}>LOGOUT</span>
              </div>
            </div>
            <div className="main-content-container">
              <div className="report-container">
                <h1>Analytics</h1>
                <br />
                <p>Select user type:</p>
                <form>
                  <input type="text" placeholder="Role" name="role" list="roles" required />
                  <datalist id="roles">
                    <option value="Student" />
                    <option value="Instructor" />
                  </datalist>
                  <br />
                  <div className="qa-img">
                    <img src="./chart.svg" alt="image1" className="qa-img" />
                  </div>
                </form>
              </div>
              <div className="chat-feedback">
                <a href="./feedback.html" className="feedback-link">Feedback</a>
                <div className="chat-icon">
                  <a href="./chat.html" className="chat-hover">
                    <Link to="/chat">
                      <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                    </Link>
                  </a>
                </div>
              </div>
              <div className="footer">
                <div className="footer-content">
                  <div className="footer-left">
                    <div className="about-text-footer">
                      <br />
                      College Of Computer Science
                      <br />
                      634 Nedderman Hall
                      <br />
                      Arlington, TX 76019
                    </div>
                  </div>
                  <div className="footer-right">
                    <div className="footer-links">
                      <Link to="/homepage" className="footer-links-title">HOME</Link>
                      <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
                      <Link to="/services" className="footer-links-title">SERVICES</Link>
                      <Link to="/contactUs" className="footer-links-title">CONTACT US</Link>
                    </div>
                  </div>
                </div>
                <div>
                  <br />
                  <div className="footer-social-icons">
                    <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                    <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                    <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                    <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
                  </div>
                  <br />
                  @GradEdge Copyright 2023
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
}

export default Analytics;
