import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';



const ContactUs = () => {
  const [formData, setFormData] = useState({
    tableName: 'Course',
    columns: {
      name:'',
      email:'',
      message:''
    },
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Handle checkbox input separately to set a boolean value
    setFormData({
      ...formData,
      columns: {
        ...formData.columns,
        [name]: type === 'checkbox' ? checked : value,
      },
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post('https://rxd7078.uta.cloud/php/add_contact_us.php', formData.columns)
      .then(function (response) {
        alert('success.');  
        console.log(response.data);
        // Redirect to the login page
      })
      .catch(function (error) {
        console.error(error);
        alert('Registration failed. Please try again.');
      });
  };

  return (
    <html lang="en">
      <head>
        <link rel="stylesheet" href="style.css" />
        <title>AddCource</title>
      </head>
      <body className="vsc-initialized">
        <div id="root">
          <div className="App">
            <div className="header">
            <div className="header-left">
                    <div className="header-title">
                      <a href="/homepage">
                        <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                      </a>
                      <span className="heading">GradEdge</span>
                    </div>
                  </div>
                  <div className="header-right">
                  <Link to="/homepage"className="header-title">HOME</Link>
                  <Link to="/aboutus"className="header-title">ABOUT</Link>
                  <Link to="/signup"className="header-title">SIGNUP</Link>
                  <Link to="/login"className="header-title">LOGIN</Link>
                  <Link to="/settings_foreveryrole"className="header-title">PROFILE</Link>
                  </div>            </div>
            <div className="main-content-container">
              <div className="container">
                <div className="form-container">
                <h2>Contact Us</h2>
              <p className="highlighted-text">Feel free to connect with us via:</p>
              <div className="contact-info-box">
                <ul className="contact-details-list">
                  <li>Email: <a href="mailto:gradedge@gmail.com">gradedge@gmail.com</a></li>
                  <li>Phone: +1 (123) 456-7890</li>
                  <li>Address: 123 Main Street, City, Country</li>
                </ul>
              </div>
              <p className="highlighted-text">Else send us a message, and we will reach out to you shortly!</p>
            
                 
                  <form onSubmit={handleSubmit}>
                    <input type="text" placeholder="Name" name="name" value={formData.columns.name}
          onChange={handleChange} required />
                    <input type="text" placeholder="Email" name="email" value={formData.columns.email}
          onChange={handleChange} required />
                    <input type="text" placeholder="Message" name="message" value={formData.columns.message}
          onChange={handleChange}required />
                 
                    <button type="submit">Submit</button>
                  </form>
                
                </div>
              </div>
               <div className="chat-feedback">
          <Link to="/feedback" className="feedback-link">Feedback</Link>
            <div className="chat-icon">
              <a  className="chat-hover"><Link to="/chat">
               <Link to="/chat">
                <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link></Link>
              </a>
            </div>
          </div>
                            <div className="footer">
              <div className="footer-content">
                    <div className="footer-left">
                      <div className="about-text-footer">
                        <br />
                        College Of Computer Science
                        <br />
                        634 Nedderman Hall
                        <br />
                        Arlington, TX 76019
                      </div>
                    </div>
                    <div className="footer-right">
                      <div className="footer-links">
                        <Link to="/homepage" className="footer-links-title">
  HOME
</Link>
                       <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
               
                       <Link to="/services" className="footer-links-title">
                          SERVICES</Link>
                   
                       <Link to="/contactUs" className="footer-links-title">
                          CONTACT US</Link>
                      </div>
                    </div>
                  </div>
                  <div>
                    <br />
                    <div className="footer-social-icons">
                      <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                      <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                      <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                      <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
                    </div>
                    <br />
                    @GradEdge Copyright 2023
                  </div>              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
};

// function ContactUs() {
//   return (
//     <div id="root">
//       <div className="App">
//       <div className="header">
//             <div className="header-left">
//                     <div className="header-title">
//                       <a href="./homepage.html">
//                         <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
//                       </a>
//                       <span className="heading">GradEdge</span>
//                     </div>
//                   </div>
//                   <div className="header-right">
//                   <Link to="/homepage"className="header-title">HOME</Link>
//                   {/* <Link to="/student"className="header-title">STUDENT</Link>
//                   <Link to="/instructor"className="header-title">INSTRUCTOR</Link>
//                   <Link to="/admin"className="header-title">ADMIN</Link>
//                   <Link to="/qaofficer"className="header-title">QA OFFICER</Link>
//                   <Link to="/program_co"className="header-title">PROG CORDINATOR</Link> */}
//                   <Link to="/aboutus"className="header-title">ABOUT</Link>
//                   <Link to="/signup"className="header-title">SIGNUP</Link>
//                   <Link to="/login"className="header-title">LOGIN</Link>
//                   <Link to="/settings_foreveryrole"className="header-title">PROFILE</Link>
//                   </div>            </div>
//         <div className="main-content-container">
//           <div className="container">
//             <div className="form-container">
//               <h2>Contact Us</h2>
//               <p className="highlighted-text">Feel free to connect with us via:</p>
//               <div className="contact-info-box">
//                 <ul className="contact-details-list">
//                   <li>Email: <a href="mailto:gradedge@gmail.com">gradedge@gmail.com</a></li>
//                   <li>Phone: +1 (123) 456-7890</li>
//                   <li>Address: 123 Main Street, City, Country</li>
//                 </ul>
//               </div>
//               <p className="highlighted-text">Else send us a message, and we will reach out to you shortly!</p>
//               <form action="#" method="POST">
//                 <div className="form-group">
//                   <label htmlFor="name">Name:</label>
//                   <input type="text" id="name" name="name" required />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="email">Email:</label>
//                   <input type="email" id="email" name="email" required />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="message">Message:</label>
//                   <textarea id="message" name="message" rows="4" required></textarea>
//                 </div>
//                 <button type="submit">Submit</button>
//               </form>
//             </div>
//           </div>
//            <div className="chat-feedback">
//           <Link to="/feedback" className="feedback-link">Feedback</Link>
//           {/* <a  className="feedback-link">Feedback <Link to="/feedback"></Link></a> */}
//             {/* <a href="./feedback.html" className="feedback-link">Feedback</a> */}
//             <div className="chat-icon">
//               <a  className="chat-hover"><Link to="/chat">
//                <Link to="/chat">
//                 <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link></Link>
//               </a>
//             </div>
//           </div>
//                             <div className="footer">
//               <div className="footer-content">
//                     <div className="footer-left">
//                       <div className="about-text-footer">
//                         <br />
//                         College Of Computer Science
//                         <br />
//                         634 Nedderman Hall
//                         <br />
//                         Arlington, TX 76019
//                       </div>
//                     </div>
//                     <div className="footer-right">
//                       <div className="footer-links">
//                         <Link to="/homepage" className="footer-links-title">
//   HOME
// </Link>
//                        <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
               
//                        <Link to="/services" className="footer-links-title">
//                           SERVICES</Link>
                   
//                        <Link to="/contactUs" className="footer-links-title">
//                           CONTACT US</Link>
//                       </div>
//                     </div>
//                   </div>
//                   <div>
//                     <br />
//                     <div className="footer-social-icons">
//                       <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
//                       <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
//                       <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
//                       <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
//                     </div>
//                     <br />
//                     @GradEdge Copyright 2023
//                   </div>              </div>
//         </div>
//       </div>
//     </div>
//   );
// }

export default ContactUs;
