import React from 'react';
import './style.css'; // Import your CSS file here
import { Link } from "react-router-dom";

function Chat() {
  return (
    <div id="root">
      <div className="App">
      <div className="header">
                            <div className="header-left">
                                <div className="header-title">
                                    <a href="./homepage.html">
                                        <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                                    </a>
                                    <span className="heading">GradEdge</span>
                                </div>
                            </div>
                            <div className="header-right">
                                <a className="header-title"><Link to="/admin">HOME</Link></a>
                                <a className="header-title"><Link to="/aboutus">ABOUT</Link></a>
                                <a className="header-title"><Link to="/homepage">LOGOUT</Link></a>
                            </div>
                        </div>
        <div className="main-content-container">
          <div className="chat-container">
            <div className="user1-chat">
              <div className="message user1-message">User 1: Good morning professor!</div>
            </div>
            <div className="user2-chat">
              <div className="message user2-message">User 2: Good morning</div>
            </div>
            <div className="user1-chat">
              <div className="message user1-message">User 1: Sir, my examination is round the corner. Can you give
                me some instructions as to how I should prepare?</div>
            </div>
            <div className="user2-chat">
              <div className="message user2-message">User 2: Certainly! But first get rid of your mobile. That is
                a major distraction.</div>
            </div>
            <div className="user1-chat">
              <div className="message user1-message">User 1: Ok, sir.</div>
            </div>
            <div className="user2-chat">
              <div className="message user2-message">User 2: Read your text thoroughly, for you must have all the
                information while you study.</div>
            </div>
            <div className="chat-input">
              <input type="text" id="message-input" placeholder="Type your message..." />
              <button id="send-button">Send</button>
            </div>
          </div>
           <div className="chat-feedback">
          <Link to="/feedback" className="feedback-link">Feedback</Link>
          {/* <a  className="feedback-link">Feedback <Link to="/feedback"></Link></a> */}
            {/* <a href="./feedback.html" className="feedback-link">Feedback</a> */}
            <div className="chat-icon">
              <a  className="chat-hover"><Link to="/chat">
               <Link to="/chat">
                <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link></Link>
              </a>
            </div>
          </div>
                            <div className="footer">
              <div className="footer-content">
                    <div className="footer-left">
                      <div className="about-text-footer">
                        <br />
                        College Of Computer Science
                        <br />
                        634 Nedderman Hall
                        <br />
                        Arlington, TX 76019
                      </div>
                    </div>
                    <div className="footer-right">
                      <div className="footer-links">
                        <Link to="/homepage" className="footer-links-title">
  HOME
</Link>
                       <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
               
                       <Link to="/services" className="footer-links-title">
                          SERVICES</Link>
                   
                       <Link to="/contactUs" className="footer-links-title">
                          CONTACT US</Link>
                      </div>
                    </div>
                  </div>
                  <div>
                    <br />
                    <div className="footer-social-icons">
                      <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                      <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                      <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                      <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
                    </div>
                    <br />
                    @GradEdge Copyright 2023
                  </div>              </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
