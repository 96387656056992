// import React from 'react';
// import './style.css'; // Import your CSS file here
// // import { Link } from "react-router-dom";
// import { Link, useHistory } from "react-router-dom";

// function InstructorProfile() {
//   const history = useHistory();
  
//     const handleLogout = () => {
//       const confirmLogout = window.confirm("ARE YOU SURE YOU WANT TO LOGOUT?");
//       if (confirmLogout) {
//         history.push('/login'); // Replace with your login page route
//       }
//       // If the user chooses not to log out, the function will simply exit without any additional actions
//     };
//   return (
//     <div id="root">
//       <div className="App">
//       <div className="header">
//                             <div className="header-left">
//                                 <div className="header-title">
//                                     <a href="./homepage.html">
//                                         <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
//                                     </a>
//                                     <span className="heading">GradEdge</span>
//                                 </div>
//                             </div>
//                             <div className="header-right">
//                             <Link to="/instructor" className="header-title">HOME</Link>
//                                <Link to="/aboutus" className="header-title">ABOUT</Link>
//                                <span className="header-title" onClick={handleLogout} style={{ cursor: 'pointer' }}>LOGOUT</span>

//                                {/* <Link to="/homepage" className="header-title">LOGOUT</Link> */}
//                             </div>
//                         </div>
//         <div className="main-content-container">
//           <div className="setting_container">
//             <div className="profile">
//               <h2>Profile Information</h2>
//               <p><strong>Name:</strong></p>
//               <p>Your Name</p>
//               <p><strong>Email:</strong></p>
//               <p>john@example.com</p>
//               <p><strong>Courses Taught:</strong></p>
//               <p>Course 1</p>
//               <p>Course 2</p>
//               <p><strong>Bio:</strong></p>
//               <p>Write something about your teaching experience, educational background, or any other relevant info.</p>
//               <a href="./instructorupdate.html">
//               <button><Link to="/instructorupdate"className="button-links ">Update Profile</Link></button>
//               </a> 
//             </div>
//           </div>
//         </div>
//         <div className="chat-feedback">
//           <button className="feedback-button">Feedback</button>
//           <div className="chat-icon">
//             <a href="./chat.html" className="chat-hover">
//              <Link to="/chat">
//                 <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link>
//             </a>
//           </div>
//         </div>
//          <div className="chat-feedback">
//           <Link to="/feedback" className="feedback-link">Feedback</Link>
//           {/* <a  className="feedback-link">Feedback <Link to="/feedback"></Link></a> */}
//             {/* <a href="./feedback.html" className="feedback-link">Feedback</a> */}
//             <div className="chat-icon">
//               <a  className="chat-hover"><Link to="/chat">
//                <Link to="/chat">
//                 <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link></Link>
//               </a>
//             </div>
//           </div>
//                             <div className="footer">
//               <div className="footer-content">
//                     <div className="footer-left">
//                       <div className="about-text-footer">
//                         <br />
//                         College Of Computer Science
//                         <br />
//                         634 Nedderman Hall
//                         <br />
//                         Arlington, TX 76019
//                       </div>
//                     </div>
//                     <div className="footer-right">
//                       <div className="footer-links">
//                         <Link to="/homepage" className="footer-links-title">
//   HOME
// </Link>
//                        <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
               
//                        <Link to="/services" className="footer-links-title">
//                           SERVICES</Link>
                   
//                        <Link to="/contactUs" className="footer-links-title">
//                           CONTACT US</Link>
//                       </div>
//                     </div>
//                   </div>
//                   <div>
//                     <br />
//                     <div className="footer-social-icons">
//                       <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
//                       <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
//                       <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
//                       <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
//                     </div>
//                     <br />
//                     @GradEdge Copyright 2023
//                   </div>              </div>
//       </div>
//     </div>
//   );
// }

// export default InstructorProfile;

import React from 'react';
import { Link } from 'react-router-dom';
import './style.css'; // Import your CSS file here

function InstructorProfile() {
  return (
    <div id="root">
      <div className="App">
        <div className="header">
          <div className="header-left">
            <div className="header-title">
              <Link to="/homepage">
                <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
              </Link>
              <span className="heading">GradEdge</span>
            </div>
          </div>
          <div className="header-right">
            <Link to="/instructor" className="header-title">HOME</Link>
            <Link to="/aboutus" className="header-title">ABOUT</Link>
            <Link to="/login" className="header-title">LOGOUT</Link>
          </div>
        </div>
        <div className="main-content-container">
          <div className="setting_container">
            <div className="profile">
              <h2>Profile Information</h2>
              <p><strong>Name:</strong></p>
              <p>Your Name</p>
              <p><strong>Email:</strong></p>
              <p>john@example.com</p>
              <p><strong>Courses Taught:</strong></p>
              <p>Course 1</p>
              <p>Course 2</p>
              <p><strong>Bio:</strong></p>
              <p>Write something about your teaching experience, educational background, or any other relevant info.</p>
              <Link to="/instructorupdate">
                <button className="button-links">Update Profile</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="chat-feedback">
          <button className="feedback-button">Feedback</button>
          <div className="chat-icon">
            <Link to="/chat">
              <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
            </Link>
          </div>
        </div>
        <div className="chat-feedback">
          <Link to="/feedback" className="feedback-link">Feedback</Link>
          <div className="chat-icon">
            <Link to="/chat">
              <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
            </Link>
          </div>
        </div>
        <div className="footer">
          <div className="footer-content">
            <div className="footer-left">
              <div className="about-text-footer">
                <br />
                College Of Computer Science
                <br />
                634 Nedderman Hall
                <br />
                Arlington, TX 76019
              </div>
            </div>
            <div className="footer-right">
              <div className="footer-links">
                <Link to="/homepage" className="footer-links-title">HOME</Link>
                <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
                <Link to="/services" className="footer-links-title">SERVICES</Link>
                <Link to="/contactUs" className="footer-links-title">CONTACT US</Link>
              </div>
            </div>
          </div>
          <div>
            <br />
            <div className="footer-social-icons">
              <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
              <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
              <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
              <img src="./images.twitterLogo.png" className="icons" width="50" alt="Twitter" />
            </div>
            <br />
            @GradEdge Copyright 2023
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstructorProfile;
