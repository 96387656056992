import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const InstructorAssessmentAddGrades = () => {
  const [formData, setFormData] = useState({
    tableName: 'CourseContent',
    columns: {
      course_material:'',
      courseFile:''
      
    },
  });
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFileName = e.target.files[0].name; // Get the file name
    setFile(selectedFileName);
  };
  
  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   // Handle checkbox input separately to set a boolean value
  //   setFormData({
  //     ...formData,
  //     columns: {
  //       ...formData.columns,
  //       [name]: type === 'checkbox' ? checked : value,
  //     },
  //   });
  // };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Handle checkbox input separately to set a boolean value
    setFormData({
      ...formData,
      columns: {
        ...formData.columns,
        [name]: type === 'checkbox' ? checked : value,
      },
    });
    console.log('Value captured:', value); // Add this line for debugging
  };
  
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   if(file){
  //     const formDataObject = new FormData();
  
  //     formDataObject.set('course_material', formData.columns.course_material);
  //     formDataObject.set('courseFile',file); // Append the file to FormData
  //      console.log(formDataObject.get('course_material'),formDataObject.get('courseFile'));
  //     axios
  //     .post('https://rxd7078.uta.cloud/php/add_course_file.php', {course_material: formData.columns.course_material, courseFile: formDataObject.get('courseFile')})
  //     .then(function (response) {
  //       alert('Success.');
  //       // console.log(response.data);
  //       // Redirect to the login page or perform any other necessary actions
  //     })
  //     .catch(function (error) {
  //       console.error(error);
  //       alert('Registration failed. Please try again.');
  //     });
  //   }
  //   else{
  //     console.log('No file selected');
  //   }
  // };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if (file) {
      const formDataObject = new FormData();
  
      // Add the "course_material" value to the form data
      //formDataObject.set('course_material', formData.columns.course_material);
  
      formDataObject.set('courseFile', file); // Append the file name to FormData
  
      // Send the form data to the server
      axios
        .post('https://rxd7078.uta.cloud/php/add_course_file.php', {
          course_material: formData.columns.course_material,
          courseFile: formDataObject.get('courseFile') // Send the selected file name
        })
        .then(function (response) {
          alert('Success.');
          console.log(response.data);
        })
        .catch(function (error) {
          console.error(error);
          alert('Registration failed. Please try again.');
        });
    }
  };
  
  

  return (
    <html lang="en">
      <head>
        <link rel="stylesheet" href="style.css" />
        <title>AddCource</title>
      </head>
      <body className="vsc-initialized">
        <div id="root">
          <div className="App">
            <div className="header">
            <div className="header-left">
                    <div className="header-title">
                      <a href="./homepage.html">
                        <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                      </a>
                      <span className="heading">GradEdge</span>
                    </div>
                  </div>
                  <div className="header-right">
                  <Link to="/homepage"className="header-title">HOME</Link>
                  <Link to="/aboutus"className="header-title">ABOUT</Link>
                  <Link to="/signup"className="header-title">SIGNUP</Link>
                  <Link to="/login"className="header-title">LOGIN</Link>
                  <Link to="/settings_foreveryrole"className="header-title">PROFILE</Link>
                  </div>            </div>
            <div className="main-content-container">
              <div className="container">
                <div className="form-container">
                  <h2>Add Course</h2>
                  <form onSubmit={handleSubmit}>
                    <input type="text" placeholder="course_material" name="course_material" value={formData.columns.course_material}
          onChange={handleChange} required />
                    <input type="file" name="courseFile" accept=".pdf, .docx, .jpg" onChange={handleFileChange} required />

                    <button type="submit">Submit</button>
                  </form>
                
                </div>
              </div>
               <div className="chat-feedback">
          <Link to="/feedback" className="feedback-link">Feedback</Link>
            <div className="chat-icon">
              <a  className="chat-hover"><Link to="/chat">
               <Link to="/chat">
                <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link></Link>
              </a>
            </div>
          </div>
                            <div className="footer">
              <div className="footer-content">
                    <div className="footer-left">
                      <div className="about-text-footer">
                        <br />
                        College Of Computer Science
                        <br />
                        634 Nedderman Hall
                        <br />
                        Arlington, TX 76019
                      </div>
                    </div>
                    <div className="footer-right">
                      <div className="footer-links">
                        <Link to="/homepage" className="footer-links-title">
  HOME
</Link>
                       <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
               
                       <Link to="/services" className="footer-links-title">
                          SERVICES</Link>
                   
                       <Link to="/contactUs" className="footer-links-title">
                          CONTACT US</Link>
                      </div>
                    </div>
                  </div>
                  <div>
                    <br />
                    <div className="footer-social-icons">
                      <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                      <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                      <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                      <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
                    </div>
                    <br />
                    @GradEdge Copyright 2023
                  </div>              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
};

export default InstructorAssessmentAddGrades;