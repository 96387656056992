// import React from 'react';
// import './style.css'; // Import your CSS file here
// // import { Link } from "react-router-dom";
// import { Link, useHistory } from "react-router-dom";

// function CurriculumEvaluation() {
//   const history = useHistory();
  
//     const handleLogout = () => {
//       const confirmLogout = window.confirm("ARE YOU SURE YOU WANT TO LOGOUT?");
//       if (confirmLogout) {
//         history.push('/login'); // Replace with your login page route
//       }
//       // If the user chooses not to log out, the function will simply exit without any additional actions
//     };
//   return (
//     <div id="root">
//       <div className="App">
//       <div className="header">
//                             <div className="header-left">
//                                 <div className="header-title">
//                                     <a href="./homepage.html">
//                                         <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
//                                     </a>
//                                     <span className="heading">GradEdge</span>
//                                 </div>
//                             </div>
//                             <div className="header-right">
//                             <Link to="/qaofficer" className="header-title">HOME</Link>
//                                <Link to="/aboutus" className="header-title">ABOUT</Link>
//                                <span className="header-title" onClick={handleLogout} style={{ cursor: 'pointer' }}>LOGOUT</span>

//                                {/* <Link to="/homepage" className="header-title">LOGOUT</Link> */}
//                             </div>
//                         </div>
//         <div className="main-content-container">
//           <div className="container">
//             <h2>Curriculum Evaluation</h2>
//             <br />
//             <form>
//               <label htmlFor="name">Receiver Name:</label>
//               <input type="text" id="name" name="name" required />

//               <label htmlFor="email">Receiver's Email:</label>
//               <input type="email" id="email" name="email" required />

//               <label htmlFor="feedback">Curriculum Feedback:</label>
//               <textarea id="feedback" name="feedback" rows="6" required></textarea>

//               <button type="submit">Submit Feedback</button>
//             </form>
//           </div>
//            <div className="chat-feedback">
//           <Link to="/feedback" className="feedback-link">Feedback</Link>
//           {/* <a  className="feedback-link">Feedback <Link to="/feedback"></Link></a> */}
//             {/* <a href="./feedback.html" className="feedback-link">Feedback</a> */}
//             <div className="chat-icon">
//               <a  className="chat-hover"><Link to="/chat">
//                <Link to="/chat">
//                 <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link></Link>
//               </a>
//             </div>
//           </div>
//                             <div className="footer">
//               <div className="footer-content">
//                     <div className="footer-left">
//                       <div className="about-text-footer">
//                         <br />
//                         College Of Computer Science
//                         <br />
//                         634 Nedderman Hall
//                         <br />
//                         Arlington, TX 76019
//                       </div>
//                     </div>
//                     <div className="footer-right">
//                       <div className="footer-links">
//                         <Link to="/homepage" className="footer-links-title">
//   HOME
// </Link>
//                        <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
               
//                        <Link to="/services" className="footer-links-title">
//                           SERVICES</Link>
                   
//                        <Link to="/contactUs" className="footer-links-title">
//                           CONTACT US</Link>
//                       </div>
//                     </div>
//                   </div>
//                   <div>
//                     <br />
//                     <div className="footer-social-icons">
//                       <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
//                       <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
//                       <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
//                       <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
//                     </div>
//                     <br />
//                     @GradEdge Copyright 2023
//                   </div>              </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CurriculumEvaluation;
import React from 'react';
import { Link } from 'react-router-dom';
import './style.css'; // Import your CSS file here

function CurriculumEvaluation() {
  return (
    <div id="root">
      <div className="App">
        <div className="header">
          <div className="header-left">
            <div className="header-title">
              <Link to="/homepage">
                <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
              </Link>
              <span className="heading">GradEdge</span>
            </div>
          </div>
          <div className="header-right">
            <Link to="/qaofficer" className="header-title">HOME</Link>
            <Link to="/aboutus" className="header-title">ABOUT</Link>
            <Link to="/login" className="header-title">LOGOUT</Link>
          </div>
        </div>
        <div className="main-content-container">
          <div className="container">
            <h2>Curriculum Evaluation</h2>
            <br />
            <form>
              <label htmlFor="name">Receiver Name:</label>
              <input type="text" id="name" name="name" required />

              <label htmlFor="email">Receiver's Email:</label>
              <input type="email" id="email" name="email" required />

              <label htmlFor="feedback">Curriculum Feedback:</label>
              <textarea id="feedback" name="feedback" rows="6" required></textarea>

              <button type="submit">Submit Feedback</button>
            </form>
          </div>
          <div className="chat-feedback">
            <Link to="/feedback" className="feedback-link">
              Feedback
            </Link>
            <div className="chat-icon">
              <a className="chat-hover">
                <Link to="/chat">
                  <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                </Link>
              </a>
            </div>
          </div>
          <div className="footer">
            <div className="footer-content">
              <div className="footer-left">
                <div className="about-text-footer">
                  <br />
                  College Of Computer Science
                  <br />
                  634 Nedderman Hall
                  <br />
                  Arlington, TX 76019
                </div>
              </div>
              <div className="footer-right">
                <div className="footer-links">
                  <Link to="/homepage" className="footer-links-title">
                    HOME
                  </Link>
                  <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
                  <Link to="/services" className="footer-links-title">
                    SERVICES
                  </Link>
                  <Link to="/contactUs" className="footer-links-title">
                    CONTACT US
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <br />
              <div className="footer-social-icons">
                <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                <img src="./images/linkedinLogo.png" className ="icons" width="50" alt="LinkedIn" />
                <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
              </div>
              <br />
              @GradEdge Copyright 2023
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CurriculumEvaluation;

