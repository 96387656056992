import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const MainFeedback = () => {
  const [formData, setFormData] = useState({
    tableName: 'Feedback',
    columns: {
      sender:'',
      Message:'',
      reply_msg:''
    },
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Handle checkbox input separately to set a boolean value
    setFormData({
      ...formData,
      columns: {
        ...formData.columns,
        [name]: type === 'checkbox' ? checked : value,
      },
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post('https://rxd7078.uta.cloud/php/add_feedback.php', formData.columns)
      .then(function (response) {
        alert('success.');  
        console.log(response.data);
        // Redirect to the login page
      })
      .catch(function (error) {
        console.error(error);
        alert('Registration failed. Please try again.');
      });
  };

  return (
    <html lang="en">
      <head>
        <link rel="stylesheet" href="style.css" />
        <title>AddCource</title>
      </head>
      <body className="vsc-initialized">
        <div id="root">
          <div className="App">
            <div className="header">
            <div className="header-left">
                    <div className="header-title">
                      <a href="./homepage.html">
                        <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                      </a>
                      <span className="heading">GradEdge</span>
                    </div>
                  </div>
                  <div className="header-right">
                  <Link to="/homepage"className="header-title">HOME</Link>
                  <Link to="/aboutus"className="header-title">ABOUT</Link>
                  <Link to="/signup"className="header-title">SIGNUP</Link>
                  <Link to="/login"className="header-title">LOGIN</Link>
                  <Link to="/settings_foreveryrole"className="header-title">PROFILE</Link>
                  </div>            </div>
            <div className="main-content-container">
              <div className="container">
                <div className="form-container">
                  <h2>Add Feedback</h2>
                  <form onSubmit={handleSubmit}>
                    <input type="email" placeholder="sender's email" name="sender" value={formData.columns.sender}
          onChange={handleChange} required />
                    {/* <input type="textarea" placeholder="Message" name="Message" value={formData.columns.Message}
          onChange={handleChange} required /> */}
                   <textarea
        placeholder="Message"
        name="Message"
        value={formData.Message}
        onChange={handleChange}
        row="10"
        required
      />
                    <button type="submit">Submit</button>
                  </form>
                
                </div>
              </div>
               <div className="chat-feedback">
          <Link to="/feedback" className="feedback-link">Feedback</Link>
            <div className="chat-icon">
              <a  className="chat-hover"><Link to="/chat">
               <Link to="/chat">
                <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link></Link>
              </a>
            </div>
          </div>
                            <div className="footer">
              <div className="footer-content">
                    <div className="footer-left">
                      <div className="about-text-footer">
                        <br />
                        College Of Computer Science
                        <br />
                        634 Nedderman Hall
                        <br />
                        Arlington, TX 76019
                      </div>
                    </div>
                    <div className="footer-right">
                      <div className="footer-links">
                        <Link to="/homepage" className="footer-links-title">
  HOME
</Link>
                       <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
               
                       <Link to="/services" className="footer-links-title">
                          SERVICES</Link>
                   
                       <Link to="/contactUs" className="footer-links-title">
                          CONTACT US</Link>
                      </div>
                    </div>
                  </div>
                  <div>
                    <br />
                    <div className="footer-social-icons">
                      <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                      <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                      <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                      <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
                    </div>
                    <br />
                    @GradEdge Copyright 2023
                  </div>              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
};

export default MainFeedback;