

import React from 'react';
import './style.css'; // Import your CSS file here
import { Link } from 'react-router-dom';

function Student() {
  return (
    <html lang="en">
      <head>
        <link rel="stylesheet" href="style.css" />
        <title>Student Dashboard</title>
      </head>
      <body className="vsc-initialized">
        <div id="root">
          <div className="App">
            <div className="header">
              <div className="header-left">
                <div className="header-title">
                  <a href="./homepage.html">
                    <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                  </a>
                  <span className="heading">GradEdge</span>
                </div>
              </div>
              <div className="header-right">
                <Link to="/student" className="header-title">HOME</Link>
                <Link to="/aboutus" className="header-title">ABOUT</Link>
                <span className="header-title" style={{ cursor: 'pointer' }}>LOGOUT</span>
                {/* <Link to="/homepage" className="header-title">LOGOUT</Link> */}
              </div>
            </div>
            <div className="main-content-container">
              <div className="qa-container">
                <section className="main">
                  <div className="main-top" style={{ marginLeft: '70px' }}>
                    <h1>Course Dashboard</h1>
                    <i className="fas fa-user-cog"></i>
                  </div>
                  <div className="main-skills" style={{ marginLeft: '100px' }}>
                    <div className="card" style={{ marginLeft: '70px' }}>
                      <i className="fas fa-laptop-code"></i>
                      <h3>Syllabus</h3>
                      <a href="./2023-FALL_2238-CSE-5315-002.pdf">
                        <button>View</button>
                      </a>
                    </div>
                    <div className="card">
                      <i className="fab fa-wordpress"></i>
                      <h3>Exams</h3>
                      <button>
                        <Link to="/student_exams3" className="button-links">
                          View
                        </Link>
                      </button>
                    </div>
                    <div className="card">
                      <i className="fas fa-palette"></i>
                      <h3>Exam Resutls</h3>
                      <button>
                        <Link to="/student_result3" className="button-links">
                          View
                        </Link>
                      </button>
                    </div>
                  </div>
                </section>
              </div>
              <div className="chat-feedback">
                <a href="./feedback.html" className="feedback-link">
                  Feedback
                </a>
                <div className="chat-icon">
                  <a href="./chat.html" className="chat-hover">
                    <Link to="/chat">
                      <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                    </Link>
                  </a>
                </div>
              </div>
              <div className="chat-feedback">
                <Link to="/feedback" className="feedback-link">
                  Feedback
                </Link>
                {/* <a  className="feedback-link">Feedback <Link to="/feedback"></Link></a> */}
                {/* <a href="./feedback.html" className="feedback-link">Feedback</a> */}
                <div className="chat-icon">
                  <a className="chat-hover">
                    <Link to="/chat">
                      <Link to="/chat">
                        <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                      </Link>
                    </Link>
                  </a>
                </div>
              </div>
              <div className="footer">
                <div className="footer-content">
                  <div className="footer-left">
                    <div className="about-text-footer">
                      <br />
                      College Of Computer Science
                      <br />
                      634 Nedderman Hall
                      <br />
                      Arlington, TX 76019
                    </div>
                  </div>
                  <div className="footer-right">
                    <div className="footer-links">
                      <Link to="/homepage" className="footer-links-title">
                        HOME
                      </Link>
                      <Link to="/aboutus" className="footer-links-title">
                        ABOUT
                      </Link>
                      <Link to="/services" className="footer-links-title">
                        SERVICES
                      </Link>
                      <Link to="/contactUs" className="footer-links-title">
                        CONTACT US
                      </Link>
                    </div>
                  </div>
                </div>
                <div>
                  <br />
                  <div className="footer-social-icons">
                    <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                    <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                    <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                    <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
                  </div>
                  <br />
                  @GradEdge Copyright 2023
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
}

export default Student;

