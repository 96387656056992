import React from 'react';
import './style.css'; // Import your CSS file if needed
import { Link } from "react-router-dom";


function CreatePolicy() {
  return (
    <div className="App">
     <div className="header">
                            <div className="header-left">
                                <div className="header-title">
                                    <a href="./homepage.html">
                                        <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                                    </a>
                                    <span className="heading">GradEdge</span>
                                </div>
                            </div>
                            <div className="header-right">
                            <Link to="/qaofficer" className="header-title">HOME</Link>
                               <Link to="/aboutus" className="header-title">ABOUT</Link>
                               <Link to="/homepage" className="header-title">LOGOUT</Link>
                            </div>
                        </div>
      <div className="main-content-container">
        <div className="container">
          <h1>Create Policy</h1>
          <form>
            <label htmlFor="policyName">Policy Name:</label>
            <input type="text" id="policyName" name="policyName" placeholder="Enter policy name" required />

            <label htmlFor="policyDescription">Policy Description:</label>
            <textarea id="policyDescription" name="policyDescription" rows="4" placeholder="Enter policy description" required></textarea>

            {/* Add more form fields for creating policy details here */}
            
            <button type="submit">Create Policy</button>
          </form>
        </div>
         <div className="chat-feedback">
          <Link to="/feedback" className="feedback-link">Feedback</Link>
          {/* <a  className="feedback-link">Feedback <Link to="/feedback"></Link></a> */}
            {/* <a href="./feedback.html" className="feedback-link">Feedback</a> */}
            <div className="chat-icon">
              <a  className="chat-hover"><Link to="/chat">
               <Link to="/chat">
                <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link></Link>
              </a>
            </div>
          </div>
                            <div className="footer">
              <div className="footer-content">
                    <div className="footer-left">
                      <div className="about-text-footer">
                        <br />
                        College Of Computer Science
                        <br />
                        634 Nedderman Hall
                        <br />
                        Arlington, TX 76019
                      </div>
                    </div>
                    <div className="footer-right">
                      <div className="footer-links">
                        <Link to="/homepage" className="footer-links-title">
  HOME
</Link>
                       <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
               
                       <Link to="/services" className="footer-links-title">
                          SERVICES</Link>
                   
                       <Link to="/contactUs" className="footer-links-title">
                          CONTACT US</Link>
                      </div>
                    </div>
                  </div>
                  <div>
                    <br />
                    <div className="footer-social-icons">
                      <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                      <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                      <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                      <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
                    </div>
                    <br />
                    @GradEdge Copyright 2023
                  </div>              </div>
      </div>
    </div>
  );
}

export default CreatePolicy;
