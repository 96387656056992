// import React from 'react';
// import './style.css'; // Import your CSS file here
// // import { Link } from "react-router-dom";
// import { Link, useHistory } from "react-router-dom";

// function ManageUsers() {
//   const history = useHistory();
  
//     const handleLogout = () => {
//       const confirmLogout = window.confirm("ARE YOU SURE YOU WANT TO LOGOUT?");
//       if (confirmLogout) {
//         history.push('/login'); // Replace with your login page route
//       }
//       // If the user chooses not to log out, the function will simply exit without any additional actions
//     };
//   return (
//     <div id="root">
//       <div className="App">
//       <div className="header">
//                             <div className="header-left">
//                                 <div className="header-title">
//                                     <a href="./homepage.html">
//                                         <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
//                                     </a>
//                                     <span className="heading">GradEdge</span>
//                                 </div>
//                             </div>
//                             <div className="header-right">
//                             <Link to="/admin" className="header-title">HOME</Link>
//                                <Link to="/aboutus" className="header-title">ABOUT</Link>
//                                <span className="header-title" onClick={handleLogout} style={{ cursor: 'pointer' }}>LOGOUT</span>

//                                {/* <Link to="/homepage" className="header-title">LOGOUT</Link> */}
//                             </div>
//                         </div>
//         <div className="main-content-container">
//           <div className="container">
//             <h1>Manage Users</h1>
//             <label>Role:</label>
//             <input
//               type="text"
//               placeholder="Student"
//               name="role"
//               style={{ width: '200px' }}
//               list="roles"
//               required
//             />
//             <datalist id="roles">
//               <option value="Student" />
//               <option value="Instructor" />
//               <option value="Admin" />
//               <option value="Program Coordinator" />
//               <option value="QA Officer" />
//             </datalist>
//             <div className="form-container_admin">
//               <h2>Details</h2>
//               <form action="#" method="POST">
//                 <label htmlFor="firstname">Firstname:</label>
//                 <input type="text" placeholder="abc" name="firstname" required />
//                 <label htmlFor="lastname">Lastname:</label>
//                 <input type="text" placeholder="xyz" name="lastname" required />
//                 <label htmlFor="email">Email:</label>
//                 <input type="email" placeholder="abc@gmail.com" name="email" required />
//                 <label htmlFor="Phonenumber">Phonenumber:</label>
//                 <input type="text" placeholder="1234" name="Phonenumber" required />
//                 <label htmlFor="course">Course:</label>
//                 <input type="text" placeholder="course1" name="course" list="course" required/>
//                 <datalist id="course">
//                   <option value="course1" />
//                   <option value="course2" />
//                   <option value="course3" />
//                 </datalist>
//                 <label htmlFor="address">Address:</label>
//                 <textarea id="message" name="message" placeholder="address" rows="4" required />
//                 <label htmlFor="GPA">GPA:</label>
//                 <input type="text" placeholder="3/4" name="GPA" required />
//                 <button type="submit">Submit</button>
//               </form>
//             </div>
//           </div>
//            <div className="chat-feedback">
//           <Link to="/feedback" className="feedback-link">Feedback</Link>
//           {/* <a  className="feedback-link">Feedback <Link to="/feedback"></Link></a> */}
//             {/* <a href="./feedback.html" className="feedback-link">Feedback</a> */}
//             <div className="chat-icon">
//               <a  className="chat-hover"><Link to="/chat">
//                <Link to="/chat">
//                 <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link></Link>
//               </a>
//             </div>
//           </div>
//                             <div className="footer">
//               <div className="footer-content">
//                     <div className="footer-left">
//                       <div className="about-text-footer">
//                         <br />
//                         College Of Computer Science
//                         <br />
//                         634 Nedderman Hall
//                         <br />
//                         Arlington, TX 76019
//                       </div>
//                     </div>
//                     <div className="footer-right">
//                       <div className="footer-links">
//                         <Link to="/homepage" className="footer-links-title">
//   HOME
// </Link>
//                        <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
               
//                        <Link to="/services" className="footer-links-title">
//                           SERVICES</Link>
                   
//                        <Link to="/contactUs" className="footer-links-title">
//                           CONTACT US</Link>
//                       </div>
//                     </div>
//                   </div>
//                   <div>
//                     <br />
//                     <div className="footer-social-icons">
//                       <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
//                       <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
//                       <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
//                       <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
//                     </div>
//                     <br />
//                     @GradEdge Copyright 2023
//                   </div>              </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ManageUsers;
import React from 'react';
import './style.css'; // Import your CSS file
import { Link } from "react-router-dom"; // Import Link from react-router-dom

function ManageUsers() {
  const handleLogout = () => {
    const confirmLogout = window.confirm("ARE YOU SURE YOU WANT TO LOGOUT?");
    if (confirmLogout) {
      // Use the <Link> component for navigation
      // Replace with your login page route
      return <Link to="/login" />;
    }
    // If the user chooses not to log out, the function will simply exit without any additional actions
  };

  return (
    <div id="root">
      <div className="App">
        <div className="header">
          <div className="header-left">
            <div className="header-title">
              <Link to="/homepage"> {/* Use Link component for navigation */}
                <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
              </Link>
              <span className="heading">GradEdge</span>
            </div>
          </div>
          <div className="header-right">
            <Link to="/admin" className="header-title">HOME</Link>
            <Link to="/aboutus" className="header-title">ABOUT</Link>
            {/* Use the Link component for logout */}
            <span className="header-title" onClick={handleLogout} style={{ cursor: 'pointer' }}>
              LOGOUT
            </span>
          </div>
        </div>
        <div className="main-content-container">
          <div className="container">
            <h1>Manage Users</h1>
            <label>Role:</label>
            <input
              type="text"
              placeholder="Student"
              name="role"
              style={{ width: '200px' }}
              list="roles"
              required
            />
            <datalist id="roles">
              <option value="Student" />
              <option value="Instructor" />
              <option value="Admin" />
              <option value="Program Coordinator" />
              <option value="QA Officer" />
            </datalist>
            <div className="form-container_admin">
              <h2>Details</h2>
              <form action="#" method="POST">
                <label htmlFor="firstname">Firstname:</label>
                <input type="text" placeholder="abc" name="firstname" required />
                <label htmlFor="lastname">Lastname:</label>
                <input type="text" placeholder="xyz" name="lastname" required />
                <label htmlFor="email">Email:</label>
                <input type="email" placeholder="abc@gmail.com" name="email" required />
                <label htmlFor="Phonenumber">Phonenumber:</label>
                <input type="text" placeholder="1234" name="Phonenumber" required />
                <label htmlFor="course">Course:</label>
                <input type="text" placeholder="course1" name="course" list="course" required/>
                <datalist id="course">
                  <option value="course1" />
                  <option value="course2" />
                  <option value="course3" />
                </datalist>
                <label htmlFor="address">Address:</label>
                <textarea id="message" name="message" placeholder="address" rows="4" required />
                <label htmlFor="GPA">GPA:</label>
                <input type="text" placeholder="3/4" name="GPA" required />
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
          <div className="chat-feedback">
            <Link to="/feedback" className="feedback-link">Feedback</Link>
            <div className="chat-icon">
              <a className="chat-hover">
                <Link to="/chat">
                  <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                </Link>
              </a>
            </div>
          </div>
          <div className="footer">
            <div className="footer-content">
              <div className="footer-left">
                <div className="about-text-footer">
                  <br />
                  College Of Computer Science
                  <br />
                  634 Nedderman Hall
                  <br />
                  Arlington, TX 76019
                </div>
              </div>
              <div className="footer-right">
                <div className= "footer-links">
                  <Link to="/homepage" className="footer-links-title">HOME</Link>
                  <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
                  <Link to="/services" className="footer-links-title">SERVICES</Link>
                  <Link to="/contactUs" className="footer-links-title">CONTACT US</Link>
                </div>
              </div>
            </div>
            <br />
            <div className="footer-social-icons">
              <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
              <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
              <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
              <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
            </div>
            <br />
            @GradEdge Copyright 2023
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageUsers;
