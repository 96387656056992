import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './style.css';

const ManageGrades = () => {
  const [scores, setscores] = useState([]);
  const [updateData, setUpdateData] = useState({
    student_email: '',
    instructor_mail: '',
    Date: '',
    score: '',
    max_score: ''
  });
  const [editing, setEditing] = useState({}); // Track editing status for each score

  useEffect(() => {
    getAllUsers();
  }, []);

  async function getAllUsers() {
    try {
      const response = await axios.get('https://rxd7078.uta.cloud/php/fetch_all_scores.php');
      const { scores } = response.data;
      setscores(scores);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


async function updateEntry(score_id, newScore) {
    try {
      await axios.post('https://rxd7078.uta.cloud/php/update_score.php', {
        score_id,
        score: newScore,
      });
  
      // Fetch and update the scores after a successful update
      const response = await axios.get('https://rxd7078.uta.cloud/php/fetch_all_scores.php');
      const { scores } = response.data;
      setscores(scores);
  
      alert('Grade updated successfully.');
      setEditing({ ...editing, [score_id]: false }); // Stop editing
    } catch (error) {
      console.error('Error updating grade:', error);
    }
  }
  const handleLogout = () => {
    // Handle logout
  };

  return (
    <div id="root">
      <div className="App">
        <div className="header">
          {/* ... (Your header code) ... */}
        </div>
        <div className="main-content-container">
          <div className="container">
            <br />
            <h2>Manage Grades</h2>
            <table className="policy-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>student_email</th>
                  <th>Instructor email</th>
                  <th>Exam date</th>
                  <th>Score</th>
                  <th>Max score</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(scores) && scores.length > 0 ? (
                  scores.map((user, index) => (
                    <tr key={index}>
                      <td>{user.score_id}</td>
                      <td>{user.student_email}</td>
                      <td>{user.instructor_mail}</td>
                      <td>{user.Date}</td>
                      <td>
                        {editing[user.score_id] ? (
                          <input
                            type="text"
                            value={updateData.score}
                            onChange={(e) => setUpdateData({ ...updateData, score: e.target.value })}
                          />
                        ) : (
                          user.score
                        )}
                      </td>
                      <td>{user.max_score}</td>
                      <td>
                        {editing[user.score_id] ? (
                          <button onClick={() => updateEntry(user.score_id, updateData.score,user.student_email,user.instructor_mail)}>SAVE</button>
                        ) : (
                          <button onClick={() => setEditing({ ...editing, [user.score_id]: true })}>UPDATE</button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  ''
                )}
              </tbody>
            </table>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default ManageGrades;